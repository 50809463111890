import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { memo, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useWorkloadsContext } from "../../../WorkloadsContext";
import { GetPolicyTuningConfigParams, GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CustomLegend from "../../../components/CustomLegend";
import DefaultFallback from "../../../components/DefaultFallback";
import SnapshotWrapper from "../../../components/SnapshotWrapper";
import CappingBadge from "./CappingBadge";
import ContainerFilter from "./ContainerFilter";
import CostBarChartContainer from "./CostBarChartContainer";
import DiagnosticsContainer from "./Diagnostics/DiagnosticsContainer";
import NumberOfReplicas from "./NumberOfReplicas";
import PendingWarning from "./PendingWarning";
import RolloutUpdateStrategyBadge from "./RolloutUpdateStrategyBadge";
import SaveAndAutomateButtons from "./SaveAndAutomateButtons";
import SelectPolicy from "./SelectPolicy";
import SelectViewPeriod from "./SelectViewPeriod";
import UsageAndMemoryCharts from "./UsageAndMemoryCharts";
import WastedResourcesWarning from "./WastedResourcesWarning";
import WorkloadTagsContainer from "./WorkloadTags/WorkloadTagsContainer";
import {
  ChartComponents,
  componentStyle,
  displayNameFormatter,
  getEndDate,
  Policy,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";

const TopSectionDivider = () => <div className="bg-border w-[1px] h-16" />;

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  fetchWorkloads: () => void;
  tuningParams: GetPolicyTuningConfigParamsResponse;
  setTuningParams: React.Dispatch<React.SetStateAction<GetPolicyTuningConfigParamsResponse>>;
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: React.Dispatch<React.SetStateAction<Policy | undefined>>;
  selectedChartComponents: ChartComponents[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<ChartComponents[]>>;
  cpuCappingConfig: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined;
  setCpuCappingConfig: React.Dispatch<
    React.SetStateAction<components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined>
  >;
  memoryCappingConfig: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined;
  setMemoryCappingConfig: React.Dispatch<
    React.SetStateAction<components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined>
  >;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
}

const HAS_DIAGNOSTIC_GRAPH = true;

const PolicyTuningContainer = memo(
  ({
    selectedWorkload,
    fetchWorkloads,
    tuningParams,
    setTuningParams,
    selectedPolicy,
    setSelectedPolicy,
    selectedChartComponents,
    setSelectedChartComponents,
    cpuCappingConfig,
    setCpuCappingConfig,
    memoryCappingConfig,
    setMemoryCappingConfig,
    isAutomate,
    setIsAutomate,
  }: Props) => {
    const { overriddenWorkloadsIds } = useWorkloadsContext();
    const getPolicyTuningConfigParams = GetPolicyTuningConfigParams();
    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

    useEffect(() => {
      setSelectedChartComponents([
        ChartComponents.AverageUsage,
        ChartComponents.RecommendedRequest,
        ChartComponents.CurrentRequest,
      ]);

      const isCreatedAtLessThan4Hours =
        selectedWorkload.createdAt?.length > 0 &&
        Number(selectedWorkload.createdAt) !== 0 &&
        dayjs().diff(adjustedDayjs(selectedWorkload.createdAt), "hour") < 4;

      setSelectedViewPeriod(isCreatedAtLessThan4Hours ? ViewPeriodOptions["4 hours"] : ViewPeriodOptions["1 day"]);
    }, []);

    const { data: dataTunningParams } = useQuery<GetPolicyTuningConfigParamsResponse, Error>({
      queryKey: [getPolicyTuningConfigParams.queryKey, selectedWorkload.id, selectedPolicy],
      queryFn: () =>
        getPolicyTuningConfigParams.queryFn({
          policyName: selectedPolicy?.name ?? "",
          namespace,
          name,
        }),
    });

    useEffect(() => {
      if (dataTunningParams) {
        setTuningParams({
          cpuPolicyTuningParams: { ...dataTunningParams.cpuPolicyTuningParams },
          memoryPolicyTuningParams: { ...dataTunningParams.memoryPolicyTuningParams },
        });
      }
    }, [dataTunningParams]);

    return (
      <div className="flex flex-col gap-4 w-full">
        <div className="border rounded-lg border-border p-4 flex flex-col gap-2">
          <div className="flex gap-6 items-center">
            <div className="w-fit flex gap-8">
              {selectedPolicy && (
                <ErrorBoundary
                  fallback={<DefaultFallback message="Failed to load Selected Policy. Please check your setup." />}
                >
                  <SelectPolicy
                    selectedPolicy={selectedPolicy}
                    namespace={selectedWorkload.namespace}
                    setSelectedPolicy={setSelectedPolicy}
                    isOverridePolicy={overriddenWorkloadsIds.includes(selectedWorkload.id)}
                    smartPolicyName={selectedWorkload.smartPolicyName}
                  />
                </ErrorBoundary>
              )}
              <ErrorBoundary
                fallback={<DefaultFallback message="Failed to load Selected View Period. Please check your setup." />}
              >
                <SelectViewPeriod />
              </ErrorBoundary>
              <ContainerFilter
                namespace={selectedWorkload.namespace}
                workloadName={selectedWorkload.workloadName}
                workloadType={selectedWorkload.type}
              />
            </div>
            <TopSectionDivider />
            {!!tuningParams?.cpuPolicyTuningParams?.historyWindow &&
              !!tuningParams?.memoryPolicyTuningParams?.historyWindow && (
                <div className="grow">
                  <div className="max-w-[500px]">
                    <CostBarChartContainer selectedWorkload={selectedWorkload} tuningParams={tuningParams} />
                  </div>
                </div>
              )}
            <TopSectionDivider />
            <div className="flex flex-col w-fit justify-end gap-1.5">
              <ErrorBoundary
                fallback={<DefaultFallback message="Failed to load Cost Bar Chart. Please check your setup." />}
              >
                <div className="relative cursor-default">
                  <NumberOfReplicas
                    runningReplicas={selectedWorkload.runningReplicas}
                    replicas={selectedWorkload.replicas}
                    selectedWorkload={selectedWorkload}
                  />
                  <CappingBadge
                    memoryCappingConfig={memoryCappingConfig}
                    cpuCappingConfig={cpuCappingConfig}
                    namespace={namespace}
                    name={name}
                    policyName={selectedPolicy?.name ?? ""}
                  />
                  <PendingWarning
                    eventReason={dataTunningParams?.updaterAnnotationEventInfo?.message}
                    eventAction={dataTunningParams?.updaterAnnotationEventInfo?.requiredAction}
                  />
                  {dataTunningParams?.RolloutUpdateStrategy?.strategyName && (
                    <SnapshotWrapper wrappedType="badge">
                      <RolloutUpdateStrategyBadge
                        strategyName={dataTunningParams?.RolloutUpdateStrategy?.strategyName ?? ""}
                        strategyType={dataTunningParams?.RolloutUpdateStrategy?.strategyType ?? ""}
                        maxSurge={dataTunningParams?.RolloutUpdateStrategy?.maxSurge}
                        maxUnavailable={dataTunningParams?.RolloutUpdateStrategy?.maxUnavailable}
                      />
                    </SnapshotWrapper>
                  )}
                  <WastedResourcesWarning wastedResourcesReasons={selectedWorkload.wastedResources} />
                </div>
              </ErrorBoundary>
            </div>
          </div>
          <WorkloadTagsContainer workload={selectedWorkload} />
        </div>
        <div>
          {tuningParams && selectedPolicy && (
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Usage and Memory Chart. Please check your setup." />}
            >
              <div className="border rounded-lg border-border">
                <UsageAndMemoryCharts
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy.name}
                  selectedChartComponents={selectedChartComponents}
                  setCpuCappingConfig={setCpuCappingConfig}
                  setMemoryCappingConfig={setMemoryCappingConfig}
                />
                <CustomLegend<ChartComponents>
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  componentStyle={componentStyle}
                  isDashedFnc={(key: string) => key.includes("Limit")}
                  ChartComponents={ChartComponents}
                  className="mt-1 pb-3"
                  displayNameFormatter={displayNameFormatter}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
        {HAS_DIAGNOSTIC_GRAPH && (
          <DiagnosticsContainer
            name={name}
            namespace={namespace}
            startDate={dayjs
              .utc(getEndDate())
              .subtract(Number(selectedViewPeriod), "hour")
              .format("YYYY-MM-DDTHH:mm:00")}
            endDate={getEndDate()}
            selectedViewPeriod={selectedViewPeriod}
          />
        )}
        <SaveAndAutomateButtons
          selectedWorkload={selectedWorkload}
          selectedPolicy={selectedPolicy}
          fetchWorkloads={fetchWorkloads}
          isAutomate={isAutomate}
          setIsAutomate={setIsAutomate}
        />
      </div>
    );
  }
);

export default PolicyTuningContainer;
